(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _outsideClick = _interopRequireDefault(require("../helpers/outsideClick.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function btnHeaderMobile() {
  // btn mobile
  var headerBtnMobile = document.querySelector('[data-header="btn-mobile"]');
  if (headerBtnMobile) {
    var handleClickHeaderBtn = function handleClickHeaderBtn(event) {
      event.preventDefault();
      html.classList.toggle('overflow');
      headerNav.classList.toggle('active');
      headerBtnMobile.classList.toggle('active');
      headerOverlay.classList.toggle('active');
      (0, _outsideClick["default"])({
        element: headerNav,
        events: ['click'],
        callback: function callback() {
          html.classList.remove('overflow');
          headerNav.classList.remove('active');
          headerBtnMobile.classList.remove('active');
          headerOverlay.classList.remove('active');
        }
      });
    };
    var html = document.documentElement;
    var headerNav = document.querySelector('[data-header="nav"]');
    var headerOverlay = document.querySelector('[data-header="overlay"]');
    headerBtnMobile.addEventListener('click', handleClickHeaderBtn);
  }
}
var _default = btnHeaderMobile;
exports["default"] = _default;

},{"../helpers/outsideClick.js":3}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function faq() {
  var btns = document.querySelectorAll('[data-faq="btn"]');
  var responses = document.querySelectorAll('[data-faq="response"]');
  if (btns.length && responses.length) {
    var toggleFaqQuestion = function toggleFaqQuestion(indexTarget) {
      btns[indexTarget].classList.toggle('active');
      responses[indexTarget].classList.toggle('active');
    };
    btns.forEach(function (btn, index) {
      btn.addEventListener('click', function () {
        return toggleFaqQuestion(index);
      });
    });
  }
}
var _default = faq;
exports["default"] = _default;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function outsideClick(_ref) {
  var element = _ref.element,
    events = _ref.events,
    callback = _ref.callback;
  var html = document.documentElement;
  var outside = 'data-outside';
  if (!element.hasAttribute(outside)) {
    events.forEach(function (userEvent) {
      setTimeout(function () {
        html.addEventListener(userEvent, handleOutsideClick);
      });
    });
    element.setAttribute(outside, '');
  }
  function handleOutsideClick(event) {
    if (!element.contains(event.target)) {
      element.removeAttribute(outside);
      events.forEach(function (userEvent) {
        html.removeEventListener(userEvent, handleOutsideClick);
      });
      callback();
    }
  }
}
var _default = outsideClick;
exports["default"] = _default;

},{}],4:[function(require,module,exports){
"use strict";

var _btnHeaderMobile = _interopRequireDefault(require("./modules/global/btnHeaderMobile.js"));
var _faq = _interopRequireDefault(require("./modules/global/faq.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
(0, _btnHeaderMobile["default"])();
(0, _faq["default"])();

},{"./modules/global/btnHeaderMobile.js":1,"./modules/global/faq.js":2}]},{},[4]);
